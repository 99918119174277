<template>
	<div class="app-teams">
		<router-view name="appbar" />
		<router-view name="drawer" />
		<router-view name="dialog" />

		<v-main>
			<Content>
				<router-view />
			</Content>
		</v-main>
	</div>
</template>

<script>
import Header from '@/components/unique/Teams/Header'
import Groups from '@/components/unique/Teams/Groups'
import Management from '@/components/unique/Teams/Management'
import Projects from '@/components/unique/Teams/Projects'
import Content from '@/components/atoms/Content'

export default {
	data: () => ({}),
	components: {
		Content,
		Header,
		Groups,
		Management,
		Projects,
	},
}
</script>

<style lang="scss" scoped>
.app-teams {
	background: #f5f5f5;
	height: 100%;
}
</style>
